import { useEffect, useState } from 'react'
import { Collapse } from 'react-collapse'
import Icon from '@/components/Icon/Icon'
import Link from 'next/link'
import { Formik } from 'formik'
import { CustomerService } from '@/lib/api/customer'
import { formatUrlSlug, getFooterMenu } from '@/lib/helper'
import { enableFooterPreview } from '@/lib/contentful/module'
import { useToasts } from 'react-toast-notifications'
import { errorEvent, socialClick, newsletterSignupEvent } from '@/lib/ga-events'

const Footer = (item) => {
  const [subscriptionError, setSubscriptionError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [send, setSend] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const footerData = getFooterMenu()
  const [props, setProps] = useState(footerData)
  const { addToast } = useToasts()

  const [isOpened, setIsOpened] = useState({
    help: false,
    buyingonline: false,
    myaccount: false,
  })
  useEffect(async () => {
    if (item?.preview) {
      const res = await enableFooterPreview(item, item?.preview ? item?.preview : false)
      setProps({ ...props, ...res })
    }
  }, [])

  const subscription = async (email) => {
    setSend(true)
    setSubscriptionError(null)

    CustomerService.newsLetterSubscription({
      email: `${email}`,
      acceptsMarketingNewsletter: true,
      acceptsAbandonedCartEmails: true,
    })
      .then((res) => {
        if (res.error) {
          setSubscriptionError(res.error)
          setSend(false)
          setSuccess(false)
        } else {
          setSubscriptionError(null)
          setSend(false)
          setSuccess(true)
          addToast('Thank you for subscribing!', { appearance: 'success' })
        }
      })
      .catch((error) => {
        console.log(error)
        if (error?.data?.http_code) {
          setSubscriptionError(
            (typeof error?.data?.error === 'string' && error?.data?.error) || error?.data?.message
          )
          setSend(false)
          setSuccess(false)
        }
      })
  }

  const toggleMenu = (key) => {
    const newIsOpened = {
      ...isOpened,
      [key]: !isOpened[key],
    }

    setIsOpened(newIsOpened)
  }

  const formatToggleKey = (key) => {
    return key.toLowerCase().split(' ').join('')
  }

  const oneTrustToggle = (event) => {
    event.preventDefault()
    OneTrust.ToggleInfoDisplay()
  }

  const footerMenu = {}

  props?.menus &&
    props?.menus?.items.forEach((menu) => {
      footerMenu[menu.title] = menu?.menuItems?.items.map((menuItem) => {
        return {
          name: menuItem?.title,
          routeUrl: menuItem?.url,
        }
      })
    })

  const handleFormShow = () => {
    try {
      if (typeof window !== 'undefined') {
        let _klOnsite = window._klOnsite || []
        _klOnsite.openForm(process.env.NEXT_PUBLIC_KLAVIYO_FOOTER_SIGNUP_FORM_ID)
        // newsletterSignupEvent(process.env.NEXT_PUBLIC_KLAVIYO_FOOTER_SIGNUP_FORM_ID)
      }
    } catch (error) {
      console.error('handleFormShow error', error)
    }
  }

  return (
    props && (
      <div className="mt-auto bg-black lg:pt-20 lg:pb-10 pb-15 pt-15 footer-section">
        <div className="container">
          <div className="flex flex-col justify-between border-gray-700 pb-15 md:pb-0 lg:pb-20 sm:border-b md:flex-row">
            <div>
              <Icon
                className={'mr-[75px] xs:mr-[50px] hidden lg:flex'}
                viewBox={`0 0 142 142`}
                size={142}
                icon={'logo-white'}
                alt={'logo'}
              />
            </div>
            <div className="flex flex-col justify-between border-b border-gray-700 basis-1/2 md:flex-row md:border-none ">
              {Object.keys(footerMenu).map((value, key) => {
                return (
                  <div data-item={key} className="wrap-title w-full md:mb-8 mx-[2.5px]" key={key}>
                    <div
                      className="flex items-center text-[16px] md:text-[14px] justify-between mb-0 md:mb-4 py-[18px] border-t border-gray-700 md:py-0 md:border-none"
                      onClick={() => toggleMenu(formatToggleKey(value))}
                    >
                      <div className="text-white font-bold uppercase tracking-[.16em]">{value}</div>
                      <Icon
                        className={`cursor-pointer text-secondary fill-white md:hidden`}
                        viewBox={`0 0 14 14`}
                        size={14}
                        icon={isOpened[formatToggleKey(value)] ? 'minus' : 'plus'}
                      />
                    </div>
                    <Collapse isOpened={isOpened[formatToggleKey(value)]}>
                      <nav>
                        <ul className="pb-4 space-y-4 text-sm text-gray-300 sm:space-y-2">
                          {footerMenu[value]?.map((item, itemKey) => {
                            return (
                              <li key={itemKey}>
                                <Link href={formatUrlSlug(item.routeUrl)} scroll={true}>
                                  <a
                                    tabIndex="-1"
                                    className="text-secondaryLightGray leading-[18px] hover:text-white mb-2 text-sm tracking-widest text-left"
                                  >
                                    {item.name}
                                  </a>
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </nav>
                    </Collapse>
                  </div>
                )
              })}
            </div>

            <div className="flex lg:basis-1/3 basis-1/2 flex-col md:mt-0 mt-15 ml-[2.5px]">
              <div className="hidden md:flex text-white text-[16px] md:text-[14px] font-bold uppercase tracking-[0.08em] mb-[16px] text-left mr-5">
                {props?.subscriptionTitle}
              </div>
              <div className="md:hidden text-white text-[16px] md:text-[14px] font-bold uppercase tracking-[0.08em] mb-[16px] text-left mr-5">
                {props?.subscriptionTitleMobile}
              </div>
              <div className="text-secondaryLightGray mb-[24px] text-sm leading-sm tracking-[0.02em] md:max-w-[334px] text-left sm:block hidden">
                {props?.subscriptionSubtitle}
              </div>

              <Formik
                initialValues={{
                  email: '',
                }}
                validate={(values) => {
                  const errors = {}

                  if (!values.email) {
                    errors.email = 'Email Address Required'
                    setDisabled(true)
                  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                    errors.email = 'Invalid email address'
                  } else {
                    setDisabled(false)
                  }

                  return errors
                }}
                onSubmit={(values) => {
                  subscription(values.email)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    className="flex w-full mb-[60px] sm:mb-[32px] sm:flex-row flex-col"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex flex-col w-full">
                      {subscriptionError ? (
                        <p className={'text-red text-[12px] mb-[5px]'}>{subscriptionError}</p>
                      ) : (
                        <span className="text-red error-box">
                          {((errors.email && touched.email) || isInvalid) && errors.email}
                        </span>
                      )}

                      <div className="md:flex">
                        <button
                          type="button"
                          onClick={handleFormShow}
                          className={`min-w-fit w-full h-[52px] md:h-auto sm:mt-0 font-bold sm:w-fit p-5 outline-none  bg-[#97A3AE] hover:bg-primary text-black focus:none text-[12px] leading-[15px] tracking-[0.08em] md:tracking-widest px-5 py-[19px] lg:px-[24px] cursor-pointer uppercase`}
                        >
                          {props?.emailCta}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>

              <div className="flex justify-center sm:justify-start">
                {props?.socialLinks &&
                  props?.socialLinks.menuItems.items.length > 0 &&
                  props?.socialLinks.menuItems.items.map((menu, index) => (
                    <a
                      href={formatUrlSlug(menu.url)}
                      target="_blank"
                      key={index}
                      data-icon={index}
                      aria-label={menu?.title}
                      onClick={() => {
                        socialClick(formatUrlSlug(menu.url), menu.title)
                      }}
                    >
                      <Icon
                        className={`cursor-pointer mr-10 md:mr-6  fill-none hover:fill-primary sm:fill-darkBlue`}
                        viewBox={`0 0 40 40`}
                        size={40}
                        icon={menu.title}
                      />
                    </a>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between pt-0 sm:pt-10 lg:flex-row ">
            <div className="flex pb-[16px] lg:pb-0 sm:border-b-0 border-b border-gray-200 flex-wrap justify-between md:justify-center ">
              {/* <div className="hidden mr-0 text-xs tracking-widest md:flex text-secondaryLightGray hover:text-white sm:mr-5">
                {props.legalText}
              </div> */}
              {props?.legalMenu?.menuItems?.items.length > 0 &&
                props?.legalMenu?.menuItems?.items.map((menu, index) => (
                  <Link href={formatUrlSlug(menu.url)} key={index}>
                    <a className="pb-[16px] md:pb-0 w-max text-secondaryLightGray hover:text-white mr-0 sm:mr-6 md:mr-4 xl:mr-8 leading-[16px] text-[14px] md:text-xs tracking-[0.01em]">
                      {menu.title}
                    </a>
                  </Link>
                ))}
              <a
                href="#"
                className="pb-[16px] md:pb-0 w-max text-secondaryLightGray hover:text-white mr-0 sm:mr-6 md:mr-4 xl:mr-8 leading-[16px] text-[14px] md:text-xs tracking-[0.01em] onetrust-infodisplay"
                onClick={oneTrustToggle}
              >
                Cookie Settings
              </a>
            </div>
            <div className="flex flex-col-reverse flex-wrap justify-start w-full pt-8 sm:w-auto sm:flex-row md:pt-0 sm:flex-nowrap sm:justify-center">
              <a
                href="tel:877-748-5247"
                className="text-[14px] md:text-xs text-secondaryLightGray hover:text-white leading-[16px] sm:ml-6 md:ml-4 xl:ml-8 ml-0 "
              >
                {props?.contactNumber}
              </a>
              <div className="flex pb-4 sm:pb-0 hover:text-white text-secondaryLightGray ">
                <div className="text-[14px] leading-[16px] font-bold sm:font-medium md:text-xs text-inherit ml-0 sm:ml-6 md:ml-4 xl:ml-8 mr-[5px]">
                  {props?.contactMessage}
                </div>
                <div className="text-[14px] leading-[16px] sm:font-medium md:text-xs text-inherit ml-0">
                  {props?.contactWorkingHours}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default Footer
